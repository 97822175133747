<template>
  <div class="input-file">
    <input type="file" accept="image/*" id="stepFile"  capture="camera" class="step-file" @change="photoFun" v-if="photoNun>0" />
    <div class="loading flex flex-x flex-y" v-if="loading">
        <div class="loading-box">
          <div class="loading-icon">
            <van-loading />
            <div class="loading-ui">上传中</div>
          </div>
        </div>
      </div>
      <div class="loading black flex flex-x flex-y" v-if="no_show">
          <div class="loading-box">
              <div class="load-bac">
                <img src="../../../images/false.png" alt=" " class="icon-img"/>
                <div class="load-no">{{no_show}}</div>
                <img src="../../../images/face1.png" alt=" " class="load-img" v-if="type==1"/>
                <img src="../../../images/tongues.png" alt=" " class="load-img" v-else/>
                <div class="again" @click="againFun">重新检测</div>
              </div>
          </div>
      </div>
      <div class="loading black flex flex-x flex-y" v-if="explain">
          <div class="loading-box">
              <div class="load-bac">
                <img src="../../../images/status-ok.png" alt=" " class="icon-img"/>
                <div class="load-explain">{{explain}}</div>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
import { mapActions , mapMutations , mapState } from 'vuex';
export default {
  name: 'inputFile',
  props: ["type"],
  computed:{
      ...mapState('step',['show','loading','no_show','explain','photoNun']),
  },
  methods:{
      photoFun(e){
        let _this = this;
        let file = e.target.files["0"];
        if (file) {
            var fileter = /^(image\/jpeg|image\/png)$/i; //检查图片格式
            if (!fileter.test(file.type)) {
              console.log("请选择jpeg，png格式的图片");
              return;
            }
            let oReader = new FileReader();
            oReader.readAsDataURL(file);
            oReader.onload = function (e) {
              var image = new Image();
              image.src = e.target.result;
              image.onload = function () {
                _this.axiosData({
                  data:{
                    photo:_this.compressImg(image),
                    type:_this.$props.type
                  },
                  _this:_this
                })
              };
            }
        }
      },
      //压缩图片
      compressImg(img){
        // 图片原始大小
        const width = img.width;
        const height = img.height;
        var resizeW = 0;
        var resizeH = 0;
        //压缩设置
        let maxSize = {
          width: 1250, //图片最大宽度
          height: 1250, //图片最大高度
        };
        //计算缩放比例
        if (width > maxSize.width || height > maxSize.height) {
          let multiple = Math.max(width / maxSize.width, height / maxSize.height);
          resizeW = width / multiple;
          resizeH = height / multiple;
        }else{
          resizeW = width;
          resizeH = height;
        }
        //画布
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        canvas.width = resizeW;
        canvas.height = resizeH;
        ctx.drawImage(img, 0, 0, resizeW, resizeH);
        const image = canvas.toDataURL('image/jpeg');
        return image;
      },
    ...mapMutations ("step", ["againFun"]),
    ...mapActions ("step", ["axiosData"])
  }
}
</script>
<style lang="scss" scoped rel="stylesheet/scss">
  @import 'inputFile';
</style>
var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('vue-header',{attrs:{"title":"舌诊","isReturn":"true"}}),(_vm.device!==3)?_c('div',[_c('div',{class:{ device: _vm.device==1?true:false }},[_vm._m(0),_vm._m(1),_c('div',{staticClass:"p3"},[_c('div',[_c('img',{staticClass:"front",attrs:{"src":require("../../../images/front.png")}}),_vm._m(2),_vm._m(3),_c('button',{staticClass:"click-photo",on:{"click":_vm.photoFun}},[_vm._v("开始舌诊")])])]),_c('div',{staticClass:"p4"},[_vm._m(4),_vm._m(5),_c('button',{staticClass:"click-photo",on:{"click":_vm.photoFun}},[_vm._v("开始舌诊")]),_vm._m(6)])]),(_vm.device!==1)?_c('input-file',{attrs:{"type":"2"}}):_vm._e()],1):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"p1"},[_c('div',[_c('img',{staticClass:"front",attrs:{"src":require("../../../images/front.png")}}),_c('div',{staticClass:"step-mint"},[_c('div',{staticClass:"mint-color"},[_vm._v("您即将进入"),_c('span',[_vm._v("[舌诊]")])])]),_c('div',{staticClass:"tips-alert"},[_c('div',{staticClass:"point-out"},[_vm._v("舌头尽量平展伸出")]),_c('div',{staticClass:"point-out"},[_vm._v("靠近摄像头，不要仰头")])]),_c('button',{staticClass:"click-photo"},[_vm._v("点击屏幕拍照")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"p2"},[_c('div',{staticClass:"hori-face"},[_c('img',{attrs:{"src":require("../../../images/hori3.png")}})]),_c('div',{staticClass:"step-mint"},[_c('div',{staticClass:"mint-color"},[_vm._v("您即将进入"),_c('span',[_vm._v("[舌诊]")])])]),_c('button',{staticClass:"click-photo"},[_vm._v("点击屏幕拍照")]),_c('div',{staticClass:"tips-alert flex flex-x"},[_c('div',{staticClass:"point-out"},[_vm._v("舌头尽量平展伸出")]),_c('div',{staticClass:"point-out"},[_vm._v("靠近摄像头，不要仰头")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"step-mint"},[_c('div',{staticClass:"mint-color"},[_vm._v("您即将进入"),_c('span',[_vm._v("[舌诊]")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tips-alert"},[_c('div',{staticClass:"point-out"},[_vm._v("舌头尽量平展伸出")]),_c('div',{staticClass:"point-out"},[_vm._v("靠近摄像头，不要仰头")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"hori-face"},[_c('img',{attrs:{"src":require("../../../images/hori3.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"step-mint"},[_c('div',{staticClass:"mint-color"},[_vm._v("您即将进入"),_c('span',[_vm._v("[舌诊]")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tips-alert flex flex-x"},[_c('div',{staticClass:"point-out"},[_vm._v("舌头尽量平展伸出")]),_c('div',{staticClass:"point-out"},[_vm._v("靠近摄像头，不要仰头")])])
}]

export { render, staticRenderFns }
<template>
  <div>
      <vue-header title="舌诊" isReturn="true" />
      <div v-if="device!==3">
      <div :class="{ device: device==1?true:false }">
        <div class="p1">
          <div>
            <img src="../../../images/front.png" class="front"/>
            <div class="step-mint">
              <div class="mint-color">您即将进入<span>[舌诊]</span></div>
            </div>
            <div class="tips-alert">
              <div class="point-out">舌头尽量平展伸出</div>
              <div class="point-out">靠近摄像头，不要仰头</div>
            </div>
            <button class="click-photo">点击屏幕拍照</button>
          </div>
        </div>
        <div class="p2">
          <div class="hori-face">
            <img src="../../../images/hori3.png"/>
          </div>
          <div class="step-mint">
            <div class="mint-color">您即将进入<span>[舌诊]</span></div>
          </div>
          <button class="click-photo">点击屏幕拍照</button>
          <div class="tips-alert flex flex-x">
            <div class="point-out">舌头尽量平展伸出</div>
            <div class="point-out">靠近摄像头，不要仰头</div>
          </div>
        </div>
        <div class="p3">
            <div>
              <img src="../../../images/front.png" class="front"/>
              <div class="step-mint">
                <div class="mint-color">您即将进入<span>[舌诊]</span></div>
              </div>
              <div class="tips-alert">
                <div class="point-out">舌头尽量平展伸出</div>
                <div class="point-out">靠近摄像头，不要仰头</div>
              </div>
              <button class="click-photo" @click="photoFun">开始舌诊</button>
            </div>
        </div>
        <div class="p4">
          <div class="hori-face">
            <img src="../../../images/hori3.png"/>
          </div>
          <div class="step-mint">
            <div class="mint-color">您即将进入<span>[舌诊]</span></div>
          </div>
          <button class="click-photo" @click="photoFun">开始舌诊</button>
          <div class="tips-alert flex flex-x">
            <div class="point-out">舌头尽量平展伸出</div>
            <div class="point-out">靠近摄像头，不要仰头</div>
          </div>
        </div>
      </div>
      <input-file type="2" v-if="device!==1" />
      </div>
  </div>
</template>
<script>
import vueHeader from '@/components/header';
import inputFile from '@/components/inputFile';
import { audioPaly } from "@/utils";
import { tongue } from "@/utils/audio";

export default {
  name: 'confirmToface',
  data(){
      return{
          device:3
      }
  },
  components:{
    vueHeader,
    inputFile
  },
  created:function(){
      let device = sessionStorage.getItem('device');
      if(device=='android' || device=='windows'){
        this.device = 1;
      }else{
        this.device = 2;
      }
  },
  methods:{
      photoFun(){
        this.$router.push({
              name:'step2',
        });
        //语音
        audioPaly.play(tongue);
      }
  }
}
</script>
<style lang="scss" scoped rel="stylesheet/scss">
  @import 'confirmtongue';
</style>